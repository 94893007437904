import React from 'react'
import { graphql, Link } from 'gatsby'
import classnames from 'classnames'
import { RichText } from 'prismic-reactjs'

import Layout from '../components/layout'
import SEO from '../components/seo'

import nophoto from '../images/nophoto.jpg'
import ArticlesColumn from '../components/ArticlesColumn'
import PageHeader from '../components/PageHeader'

import arrow from '../images/cta-arrow.svg'

export const query = graphql`
  query($articleIds: [String!]) {
    prismic {
      allArticles(id_in: $articleIds) {
        edges {
          node {
            title
            _meta {
              uid
              firstPublicationDate
            }
            image
            excerpt
            body {
              ... on PRISMIC_ArticleBodyText {
                primary {
                  text
                }
              }
            }
            focusarea {
              ... on PRISMIC_Focus_area {
                _meta {
                  uid
                }
                name
              }
            }
          }
        }
      }
    }
  }
`

const ArticlesPage = props => {
  const articles = props.data.prismic.allArticles.edges.map(edge => edge.node)

  const hasPreviousPage = props.pageContext.currentPage > 1
  const hasNextPage = props.pageContext.currentPage < props.pageContext.numPages

  const calculatedExcerpts = articles.reduce((acc, article) => {
    const firstParagraph =
      article.body && article.body[0].primary
        ? article.body[0].primary.text
        : null

    if (!firstParagraph) {
      return { ...acc, [article._meta.uid]: '' }
    }

    const full = article.body ? RichText.asText(firstParagraph) : ''

    const whereToSlice = full.slice(0, 160).substr(-1) === ' ' ? 159 : 160

    return {
      ...acc,
      [article._meta.uid]:
        full.slice(0, whereToSlice) +
        (full.slice(0, whereToSlice).substr(-1) === '.' ? '' : '...'),
    }
  }, {})

  return (
    <Layout>
      <SEO
        title={`${props.pageContext.focusAreaName} Insights`}
        keywords={[`longevity`]}
      />

      <PageHeader
        titles={[props.pageContext.focusAreaName, 'Insights']}
        lineColorClass={`bg-${props.pageContext.focusAreaUid}`}
      />

      <div className="container lg:max-w-xl mx-auto mb-24 lg:mb-32">
        <div
          className={classnames('mt-16', {
            'mb-32': props.pageContext.numPages === 1,
          })}
        >
          <ArticlesColumn
            articles={articles.map(article => ({
              title: article.title,
              uid: article._meta.uid,
              category: article.focusarea,
              description:
                article.excerpt || calculatedExcerpts[article._meta.uid],
              createdAt: article._meta.firstPublicationDate,
              image: article.image ? article.image.url : nophoto,
            }))}
            paginationArrowLink={
              props.pageContext.numPages > props.pageContext.currentPage
                ? `/insights/${props.pageContext.focusAreaUid}/${props
                    .pageContext.currentPage + 1}`
                : undefined
            }
          />
        </div>

        {props.pageContext.numPages && (
          <ul
            id="pagination"
            className="list-reset flex items-center font-sans my-16 lg:my-24 flex-wrap justify-start px-4 lg:px-0"
          >
            <li className="w-1/3">
              <Link
                to={`/insights/${props.pageContext.focusAreaUid}${
                  props.pageContext.currentPage === 2
                    ? ''
                    : `/${props.pageContext.currentPage - 1}`
                }`}
                className={classnames(
                  'font-serif text-base text-blue-dianne flex items-center no-underline',
                  {
                    invisible: !hasPreviousPage,
                  }
                )}
              >
                <img
                  src={arrow}
                  alt=""
                  className="mr-4 lg:mr-8 h-6 svg-mirror"
                />
                {/* <span className="cta-ie11-fix">Previous</span>{' '} */}
              </Link>
            </li>
            <li style={{ marginLeft: 'auto', marginRight: 'auto' }}>
              <p className="font-serif text-blue-dianne">
                Page {props.pageContext.currentPage} of{' '}
                {props.pageContext.numPages}
              </p>
            </li>
            <li className="w-1/3">
              <Link
                to={`/insights/${props.pageContext.focusAreaUid}/${props
                  .pageContext.currentPage + 1}`}
                className={classnames(
                  'font-serif text-base text-blue-dianne flex items-center justify-end no-underline',
                  {
                    invisible: !hasNextPage,
                  }
                )}
              >
                {/* <span className="cta-ie11-fix">Next</span>{' '} */}
                <img src={arrow} alt="" className="ml-4 g:ml-8 h-6" />
              </Link>
            </li>
          </ul>
        )}
      </div>
    </Layout>
  )
}

export default ArticlesPage
