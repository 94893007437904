/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql, Link } from 'gatsby'
import classnames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedinIn, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import Stickyfill from 'stickyfilljs'

import { faBars } from '@fortawesome/free-solid-svg-icons'

import SearchBar from './SearchBar'

import GdprModal from './GdprModal'

import newLogo from '../images/new-logo.svg'
import footerLogo from '../images/footer-logo.svg'
import './layout.css'
import FooterNewsletter from './FooterNewsletter'
// import CookiePolicyModal from './CookiePolicyModal'

const LINKS = [
  { to: '/', label: 'Home' },
  { to: '/about-us', label: 'About Us' },
  { to: '/insights', label: 'Insights' },
  { to: '/longevity-explorer', label: 'Longevity Explorer' },
  { to: '/longevity-network', label: 'Longevity Network' },
]

const mediaQuery =
  typeof window !== 'undefined' && window.matchMedia('(min-width: 992px)')

const Layout = ({
  children,
  isHomepage,
  stickyMenu,
  forceMenuHide,
  searchQuery = '',
}) => {
  const [visible, setVisible] = useState(false)
  const [, setMatches] = useState(mediaQuery.matches)

  useEffect(() => {
    const listener = () => setMatches(mediaQuery.matches)
    mediaQuery.addListener(listener)

    return () => {
      mediaQuery.removeListener(listener)
    }
  })

  const [menuState, setMenuState] = useState({ showMenu: true, lastScroll: 0 })

  const { lastScroll } = menuState

  const ref = useRef()

  useEffect(() => {
    if (typeof window === 'undefined') {
      return
    }
    Stickyfill.addOne(ref.current)
  }, [])

  useEffect(() => {
    if (!stickyMenu || typeof window === 'undefined') {
      return () => {}
    }

    const listener = event => {
      if (window.pageYOffset > lastScroll && menuState.showMenu) {
        setMenuState({ showMenu: false })
      }
      if (
        window.pageYOffset < lastScroll &&
        !menuState.showMenu &&
        !document.getElementById('react-aria-modal-dialog')
      ) {
        setMenuState({ showMenu: true })
      }

      setMenuState(state => ({ ...state, lastScroll: window.pageYOffset }))
    }
    window.addEventListener('scroll', listener)

    return () => {
      window.removeEventListener('scroll', listener)
    }
  }, [menuState, stickyMenu])

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <>
          <div
            ref={ref}
            className={classnames('flex items-center py-8 bg-white z-1', {
              'absolute w-full': Boolean(isHomepage),
              'sticky pin-t sticky-menu': stickyMenu,
              'sticky-menu--visible':
                stickyMenu &&
                !forceMenuHide &&
                (typeof window === 'undefined' ||
                  (window.pageYOffset < 50 &&
                    !document.getElementById('react-aria-modal-dialog')) ||
                  menuState.showMenu),
            })}
          >
            <div className="w-48 ml-8">
              <Link to="/">
                <img src={newLogo} alt="" />
              </Link>
            </div>
            <button
              onClick={() =>
                setVisible(wasVisible => {
                  document.body.classList.add('overflow-hidden')

                  return !wasVisible
                })
              }
              className="ml-auto lg:hidden mr-4 text-lg p-4 text-blue-dianne z-10"
            >
              <FontAwesomeIcon icon={faBars} />
            </button>
            <div
              className={classnames(
                'fixed pin bg-white lg:bg-transparent ml-auto lg:mr-8 lg:static lg:block z-50 navigation',
                {
                  'lg:visible lg:opacity-100': true,
                  'visible opacity-100': visible,
                  'invisible opacity-0': !visible,
                }
              )}
            >
              <button
                onClick={() =>
                  setVisible(() => {
                    document.body.classList.remove('overflow-hidden')

                    return false
                  })
                }
                className="block lg:hidden ml-auto mr-8 mt-12"
              >
                <FontAwesomeIcon
                  icon={faTimes}
                  className="text-blue-dianne text-lg"
                  size="xs"
                />
              </button>
              <ul className="flex flex-col lg:flex-row lg:mr-8 h-full items-center justify-center list-reset lg:relativex">
                {LINKS.map(link => (
                  <li key={link.label} className="lg:ml-8 my-4">
                    <Link
                      to={link.to}
                      className="text-danube no-underline hover:underline uppercase font-sans text-sm"
                      activeClassName="text-black"
                    >
                      {link.label}
                    </Link>
                  </li>
                ))}
                <li className="lg:ml-8 my-4">
                  <SearchBar searchQuery={searchQuery} />
                </li>
              </ul>
            </div>
          </div>
          {children}
          <GdprModal />
          <footer className="bg-selago py-16 lg:pt-24 lg:pb-16 px-8 lg:px-0 font-sans text-blue-dianne">
            <div className="container mx-auto lg:flex">
              <div className="lg:w-1/4">
                <Link to="/" className="block w-24">
                  <img src={footerLogo} alt="" />
                </Link>
                <p className="font-serif mt-8 text-sm text-gothic leading-normal">
                  We help to build
                  <br />a health-conscious society
                </p>
                <div className="flex items-center mt-8">
                  <a
                    href="https://twitter.com/LongevityEurope"
                    className="block mr-8 flex items-center justify-center"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon
                      icon={faTwitter}
                      className="text-blue-dianne text-base"
                    />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/longevity-institute/"
                    className="block mr-8 flex items-center justify-center"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon
                      icon={faLinkedinIn}
                      className="text-blue-dianne text-base"
                    />
                  </a>
                </div>
              </div>
              <div className="lg:w-2/3 flex-grow lg:flex mt-4 lg:mt-0 lg:pl-12">
                <div className="lg:w-1/3 mt-8 lg:mt-0">
                  <h3 className="font-sans text-blue-dianne text-lg font-normal">
                    About Us
                  </h3>
                  <ul className="list-reset mt-4">
                    <li className="mb-2">
                      <Link
                        to="/about-us"
                        className="text-blue-dianne no-underline hover:underline font-sans"
                      >
                        Our Mission
                      </Link>
                    </li>
                    <li className="mb-2">
                      <Link
                        to="/programs"
                        className="text-blue-dianne no-underline hover:underline font-sans"
                      >
                        Programs
                      </Link>
                    </li>
                    <li className="mb-2">
                      <Link
                        to="/careers"
                        className="text-blue-dianne no-underline hover:underline font-sans"
                      >
                        Careers
                      </Link>
                    </li>
                    <li className="mb-2">
                      <Link
                        to="/contact"
                        className="text-blue-dianne no-underline hover:underline font-sans"
                      >
                        Contact
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="lg:w-1/3 mt-8 lg:mt-0">
                  <h3 className="font-sans text-blue-dianne text-lg font-normal">
                    Focus Areas
                  </h3>
                  <ul className="list-reset mt-4">
                    <li className="mb-2">
                      <Link
                        to="/focus-areas/age-science"
                        className="text-blue-dianne no-underline hover:underline font-sans"
                      >
                        Age Science
                      </Link>
                    </li>
                    <li className="mb-2">
                      <Link
                        to="/focus-areas/preventive-healthcare"
                        className="text-blue-dianne no-underline hover:underline font-sans"
                      >
                        Preventive Healthcare
                      </Link>
                    </li>

                    <li className="mb-2">
                      <Link
                        to="/focus-areas/longevity-economy"
                        className="text-blue-dianne no-underline hover:underline font-sans"
                      >
                        Longevity Economy
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="lg:w-1/3 mt-8 lg:mt-0">
                  <h3 className="font-sans text-blue-dianne text-lg font-normal">
                    Newsletter
                  </h3>
                  <FooterNewsletter />
                </div>
              </div>
            </div>
            <p className="mx-auto text-center mt-16 text-sm text-gothic">
              <Link
                to="/privacy-policy"
                className="text-gothic no-underline hover:underline"
              >
                Privacy Policy
              </Link>
              <span className="mx-2">&bull;</span>
              <Link
                to="/terms-and-conditions"
                className="text-gothic no-underline hover:underline"
              >
                Terms and Conditions
              </Link>
              <span className="mx-2">&bull;</span>
              <Link
                to="/cookie-policy"
                className="text-gothic no-underline hover:underline"
              >
                Cookie Policy
              </Link>
              <br />
              Copyright &copy; {new Date().getFullYear()} International
              Institute of Longevity. All rights reserved.
            </p>
          </footer>
          {/* <CookiePolicyModal /> */}
        </>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
