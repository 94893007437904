import React, { PureComponent } from 'react'
import classnames from 'classnames'
import { navigate } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

import arrow from '../images/cta-arrow.svg'

export default class SearchBar extends PureComponent {
  inputRef = null

  constructor(props) {
    super(props)

    this.state = {
      value: props.searchQuery || '',
      isVisible: false,
    }
  }

  onChange = event => {
    this.setState({ value: event.target.value })
  }

  onButtonClick = () => {
    this.setState(
      ({ isVisible }) => ({ isVisible: !isVisible }),
      () => {
        this.inputRef.focus()
      }
    )
  }

  onSubmit = event => {
    event.preventDefault()

    this.setState({ isVisible: false })

    navigate(`/search?q=${this.state.value}`, { replace: true })
  }

  render() {
    return (
      <>
        <form
          className={classnames(
            'lg:absolute block lg:pin-l lg:pin-t lg:pin-b lg:pin-r mt-24 lg:mt-32 px-8',
            {
              'lg:hidden': !this.state.isVisible,
            }
          )}
          onSubmit={this.onSubmit}
        >
          <div className="flex bg-selago">
            <input
              type="text"
              value={this.state.value}
              onChange={this.onChange}
              placeholder="Search..."
              className="block bg-selago h-16 lg:h-24 w-full px-6 outline-none font-sans text-blue-dianne text-lg"
              ref={node => (this.inputRef = node)}
            />
            <button
              onClick={this.onSubmit}
              className="flex items-center cursor-pointer focus:outline-none font-serif text-blue-dianne text-lg px-6 lg:px-8"
            >
              <img src={arrow} alt="" className="mx-4 h-5 mt-1" />
            </button>
          </div>
        </form>
        <button
          onClick={this.onButtonClick}
          className="block cursor-pointer focus:outline-none invisible lg:visible"
          style={{ maxWidth: 20 }}
        >
          <FontAwesomeIcon
            icon={faSearch}
            className="text-base text-blue-dianne"
            fixedWidth
          />
        </button>
      </>
    )
  }
}
