import React from 'react'
import classnames from 'classnames'
import { RichText } from 'prismic-reactjs'
import { createRichTextSerializer, renderNewLineText } from '../utils/prismic'

const descriptionSerializer = createRichTextSerializer({
  paragraphClassname: ({ index }) =>
    classnames('text-lg lg:px-0 font-normal leading-loose', {
      'mt-8': index === 0,
      'mt-4': index > 0,
    }),
})

export default function PageHeader({
  titles = [],
  titleNode = null,
  descriptions = [],
  richDescription = null,
  lineColorClass = 'bg-brand-blue-4',
} = {}) {
  return (
    <div className="container lg:max-w-xl px-8 lg:px-0 mx-auto mt-16 mb-8 font-serif text-blue-dianne leading-tight">
      {titleNode !== null && titleNode}
      {titles.map((title, index) => (
        <p className="text-2xl lg:text-headline my-0" key={index}>
          {renderNewLineText(title)}
        </p>
      ))}
      <div className={classnames('w-16 h-2 mt-8', lineColorClass)} />
      {richDescription !== null
        ? RichText.render(richDescription, undefined, descriptionSerializer)
        : null}
      {descriptions
        .filter(description => Boolean(description))
        .map((description, index) => (
          <p
            className={classnames(
              'text-lg font-normal leading-normal lg:leading-loose',
              {
                'mt-8': index === 0,
                'mt-4': index > 0,
              }
            )}
            key={index}
          >
            {description}
          </p>
        ))}
    </div>
  )
}
