import React, { PureComponent } from 'react'
import { Link } from 'gatsby'
import jsonp from 'jsonp'
import qs from 'qs'

import submitArrow from '../images/submit-arrow.svg'

export default class FooterNewsletter extends PureComponent {
  state = {
    emailAddress: '',
    isSubscribed: false,
  }

  onEmailAddressChange = event => {
    this.setState({ emailAddress: event.target.value })
  }

  subscribe = event => {
    event.preventDefault()

    const { emailAddress } = this.state

    const query = {
      EMAIL: emailAddress,
      b_547c65128f016b0d58c2fe122_778e7756f8: '',
      u: '547c65128f016b0d58c2fe122',
      id: '778e7756f8',
      subscribe: 'Subscribe',
    }

    jsonp(
      `https://l-institue.us20.list-manage.com/subscribe/post-json?${qs.stringify(
        query
      )}`,
      { param: 'c' },
      (error, result) => {
        if (error) {
          console.error('error', error)

          return
        }

        this.setState({ isSubscribed: true })
      }
    )
  }

  render() {
    const { emailAddress, isSubscribed } = this.state

    if (isSubscribed) {
      return <p className="mt-4">Thanks for signing up for our newsletter!</p>
    }

    return (
      <>
        <p className="mt-4 text-gothic">Please subscribe to our newsletter.</p>
        <form onSubmit={this.subscribe}>
          <div className="flex mt-8">
            <input
              type="text"
              placeholder="Enter your email"
              className="bg-transparent outline-none block flex-shrink rounded-none text-blue-dianne p-2"
              style={{ backgroundColor: '#ffffff' }}
              value={emailAddress}
              onChange={this.onEmailAddressChange}
            />
            <button
              className="block flex-no-shrink px-4"
              style={{ backgroundColor: '#ffffff' }}
              onClick={this.subscribe}
            >
              <img src={submitArrow} alt="" className="block" />
            </button>
          </div>
        </form>
        <p className="mt-4 text-gothic text-sm">
          <Link
            to="/privacy-policy"
            className="p-0 m-0 text-gothic no-underline hover:underline"
          >
            Your privacy is protected under conditions hereby.
          </Link>
        </p>
      </>
    )
  }
}
