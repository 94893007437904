import React from 'react'
import { Link } from 'gatsby'
import AriaModal from 'react-aria-modal'

import newLogo from '../images/new-logo-small.svg'

const STORAGE_KEY = 'longevity-gdpr'

export default class GdprModal extends React.PureComponent {
  state = {
    showModal:
      typeof window !== 'undefined' &&
      !Boolean(localStorage.getItem(STORAGE_KEY)),
  }

  hideModal = () => {
    this.setState({ showModal: false })
    localStorage.setItem(STORAGE_KEY, 'accepted')
  }

  render() {
    if (!this.state.showModal) {
      return null
    }
    return (
      <AriaModal
        titleText="Expert"
        onExit={this.hideModal}
        underlayStyle={{}}
        underlayColor="rgba(111, 159, 200, 0.2)"
        focusDialog
        verticallyCenter
      >
        <div className="h-screen lg:h-auto max-w-md bg-white p-8">
          <div className="w-24 lg:w-36-7rem mx-auto">
            <img src={newLogo} alt="" className="block mx-auto" />
          </div>
          <div className="text-center font-serif mt-12">
            <p className="uppercase text-lg text-blue-dianne">Welcome to the</p>
            <p className="uppercase text-lg font-bold text-blue-dianne tracking-wide">
              International Institute of Longevity
            </p>
          </div>
          <p className="font-sans leading-normal mt-8 text-sm text-blue-dianne text-justify measure-wide">
            To enhance your experience on our site and provide you with the best
            services possible, we use cookies and other technologies to process
            your personal data. By clicking "Accept", you consent to their use.
          </p>
          <p className="font-sans leading-normal mt-4 text-sm text-blue-dianne text-justify measure-wide">
            <Link to="/privacy-policy" className="text-brand-blue-2">
              Learn more
            </Link>
            , including how to manage your privacy settings.
          </p>
          <div className="flex">
            <button
              id="accept-button"
              onClick={this.hideModal}
              className="ml-auto mt-8 bg-selago px-6 pt-3 pb-3 font-serif text-blue-dianne text-lg leading-none block"
            >
              Accept
            </button>
          </div>
        </div>
      </AriaModal>
    )
  }
}
