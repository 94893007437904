import React from 'react'
import classnames from 'classnames'

export function renderNewLineText(text) {
  const lines = text.split('\n')

  return lines.map((line, index) => (
    <React.Fragment key={index}>
      {line}
      {index < lines.length - 1 && <br />}
    </React.Fragment>
  ))
}

export function createRichTextSerializer({
  paragraphClassname = () => '',
  ulClassname = () => '',
  olClassname = () => '',
  heading2Classname = () => '',
  aClassname = () => '',
}) {
  return function serializer(
    type,
    element,
    content,
    children,
    index,
    ...restprops
  ) {
    if (type === 'paragraph') {
      return (
        <p
          key={index}
          className={paragraphClassname({
            type,
            element,
            content,
            children,
            index,
          })}
        >
          {children}
        </p>
      )
    }

    if (type === 'group-list-item') {
      return (
        <ul
          key={index}
          className={classnames(
            'mt-6',
            ulClassname({ type, element, content, children, index })
          )}
        >
          {children}
        </ul>
      )
    }

    if (type === 'group-o-list-item') {
      return (
        <ol
          key={index}
          className={classnames(
            'mt-6',
            olClassname({ type, element, content, children, index })
          )}
        >
          {children}
        </ol>
      )
    }

    if (type === 'heading2') {
      return (
        <h2
          key={index}
          className={classnames(
            'font-serif font-normal text-xl mt-6',
            heading2Classname({ type, element, content, children, index })
          )}
        >
          {children}
        </h2>
      )
    }

    if (type === 'hyperlink') {
      return (
        <a
          href={element.data.url}
          key={index}
          className={classnames(
            aClassname({ type, element, content, children, index })
          )}
          target="_blank"
          rel="noopener noreferrer"
        >
          {children}
        </a>
      )
    }

    return null
  }
}
