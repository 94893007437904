import React, { PureComponent } from 'react'
import classnames from 'classnames'
import Equalizer from './Equalizer'
import { Link, graphql, StaticQuery } from 'gatsby'
import moment from 'moment'
import { Date as PrismicDate } from 'prismic-reactjs'
import Img from 'gatsby-image'
import get from 'lodash/get'

import defaultThumbnail from '../images/related-2.jpg'
import arrow from '../images/cta-arrow.svg'

class HomeLatestArticles extends PureComponent {
  constructor(props) {
    super(props)
    if (typeof window !== 'undefined') {
      this.media = window.matchMedia('screen and (min-width: 900px)')
    }
  }

  getHeaderNodes() {
    return [this.headerNode0, this.headerNode1, this.headerNode2]
  }

  getDescriptionNodes() {
    return [this.descriptionNode0, this.descriptionNode1, this.descriptionNode2]
  }

  getNodesForEqualizer(nodePrefix) {
    return Object.getOwnPropertyNames(this)
      .filter(property => property.indexOf(nodePrefix) === 0)
      .map(property => this[property])
      .filter(node => node)
  }

  getImageForArticle(uid, images) {
    // const uid = article.uid

    const image = images.find(
      image =>{
        console.log('using uid', uid)
        return image.name === `article__image--${uid}`
      }
    )

    if (!image) {
      return null
    }

    return (
      <>
        <Img
          fluid={get(
            image,
            'childImageSharp.fluid',
            defaultThumbnail
          )}
          className={classnames(
            'block w-full original-image',
            {
              'grayscale group-hover:no-grayscale': this
                .props.grayscale,
            }
          )}
        />
        {this.props.grayscale && (
          <div className="grayscale-image group-hover:hidden">
            <Img
              fluid={
                image.childImageSharp.fluidGrayscale
              }
              className={classnames(
                'block w-full grayscale-image',
                {
                  'grayscale group-hover:no-grayscale': this
                    .props.grayscale,
                }
              )}
            />
          </div>
        )}
      </>
    )
  }

  render() {
    const query = graphql`
      query {
        allFile(filter: { name: { glob: "article__image--*" } }, limit: 1000) {
          edges {
            node {
              name
              childImageSharp {
                fluid(maxWidth: 414, quality: 85) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
                fluidGrayscale: fluid(
                  maxWidth: 414
                  quality: 85
                  grayscale: true
                ) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    `

    return (
      <StaticQuery
        query={query}
        render={data => {
          const requiredImageFiels = this.props.articles.map(
            article => `article__image--${article.uid}`
          )

          const images = data.allFile.edges
            .map(edge => edge.node)
            .filter(node => requiredImageFiels.includes(node.name))

          return (
            <>
              <Equalizer
                byRow={false}
                nodes={() => this.getNodesForEqualizer('headerNode')}
                enabled={() => (this.media ? this.media.matches : true)}
                forceGroupItemsCount
                groupItemsCount={3}
              >
                <Equalizer
                  byRow={false}
                  nodes={() => this.getNodesForEqualizer('descriptionNode')}
                  enabled={() => (this.media ? this.media.matches : true)}
                  forceGroupItemsCount
                  groupItemsCount={3}
                >
                  <div className="lg:flex lg:flex-wrap lg:-mx-4">
                    {this.props.articles.map(
                      (
                        { uid, title, description, category, image, createdAt },
                        index
                      ) => (
                        <div
                          key={index}
                          className={classnames(
                            'group grayscale-hack mb-16 lg:mb-0 lg:w-1/3 flex-shrink-0 flex-no-grow mt-16 px-4',
                            {
                              'lg:mr-12':
                                (index !== this.props.articles.length % 3) ===
                                0,
                            }
                          )}
                        >
                          <div
                            className={classnames(
                              'border-b-4',
                              `border-${category._meta.uid}`
                            )}
                          >
                            <Link
                              to={`/insight/${uid}`}
                              className="block no-underline text-blue-dianne relative"
                            >
                              {this.getImageForArticle(uid, images)}
                            </Link>
                          </div>
                          <h2
                            className="font-serif font-normal mt-10 mx-8 lg:mx-0"
                            ref={node => (this[`headerNode${index}`] = node)}
                          >
                            <Link
                              to={`/insight/${uid}`}
                              className="block no-underline text-blue-dianne"
                            >
                              {title}
                            </Link>
                          </h2>
                          <p className="font-sans text-gothic mt-4 mb-8 mx-8 lg:mx-0 text-sm">
                            {moment(PrismicDate(createdAt)).format(
                              'MMMM DD, YYYY'
                            )}
                          </p>
                          <p
                            className="leading-normal font-sans mx-8 lg:mx-0"
                            ref={node =>
                              (this[`descriptionNode${index}`] = node)
                            }
                          >
                            <Link
                              to={`/insight/${uid}`}
                              className="block no-underline text-blue-dianne"
                            >
                              {description}
                            </Link>
                          </p>
                          <p className="mt-8 font-sans text-gothic text-sm mx-8 lg:mx-0">
                            <Link
                              to={`/insight/${uid}`}
                              className="no-underline hover:underline text-gothic uppercase"
                            >
                              Read more
                            </Link>
                          </p>
                        </div>
                      )
                    )}
                    {this.props.paginationArrowLink && (
                      <div className="mt-16 px-4 flex-grow relative hidden lg:block">
                        <div className="pagination-arrow__outer">
                          <div className="pagination-arrow__inner flex items-center justify-center">
                            <Link
                              to={this.props.paginationArrowLink}
                              className="block w-24 h-24 flex items-center justify-center rounded-full border border-transparent hover:border-blue-dianne"
                            >
                              <img src={arrow} alt="" className="h-8" />
                            </Link>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </Equalizer>
              </Equalizer>
            </>
          )
        }}
      />
    )
  }
}

export default HomeLatestArticles
